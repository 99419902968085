import React, { useState } from 'react';
import authService from './auth_service.js';
import Header from './Header.js';
import Footer from './Footer.js';
import { CheckCircleIcon } from '@heroicons/react/solid'; // Importando um ícone de check verde

function ResetPasswordRequest() {
    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false); // Novo estado para o status de envio

    function handleResetPasswordRequest(event) {
        event.preventDefault();

        authService.resetPasswordRequest(email)
            .then(response => {
                if (response.status === 200) {
                    setIsSubmitted(true); // Definir status de envio como verdadeiro
                }
            })
            .catch(error => {
                if (error.response.data) {
                    alert("Erro: " + error.response.data);
                } else {
                    alert("Ocorreu um erro inesperado.");
                }
            });
    }

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <Header />
            <main className="flex-grow flex items-center justify-center bg-[url('./background.png')] bg-cover bg-center bg-no-repeat ">
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mt-10">
                    {!isSubmitted ? ( // Renderização condicional baseada em isSubmitted
                        <>
                            <h1 className="text-2xl font-semibold text-center mb-6">Redefinir Senha</h1>
                            <form onSubmit={handleResetPasswordRequest}>
                                <input
                                    className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    type="email"
                                    placeholder="Digite seu email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button
                                    className="w-full bg-gray-700 text-white py-2 rounded-lg hover:bg-black transition-colors"
                                    type="submit"
                                >
                                    Enviar
                                </button>
                            </form>
                        </>
                    ) : (
                        // Mensagem de confirmação
                        <div className="flex flex-col items-center text-center">
                            <CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" aria-hidden="true" />
                            <h2 className="text-xl font-semibold mb-2">Email Enviado!</h2>
                            <p className="text-gray-700">
                                Um email para redefinição de senha foi enviado para <strong>{email}</strong>.
                                Por favor, verifique sua caixa de entrada para mais instruções.
                            </p>
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ResetPasswordRequest;
