import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FiMail, FiMenu, FiX } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const PoliciesPage = () => {
  const { hash } = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [hash]);

  return (
    <div>
      <Header />
      <div className="flex flex-col sm:flex-row mx-auto bg-gray-200">
        {/* Mobile Navigation */}
        <div className="sm:hidden fixed top-16 left-4 z-50">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="p-2 text-gray-700 bg-white rounded-md shadow-md"
          >
            {mobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
          {mobileMenuOpen && (
            <nav className="bg-white shadow-lg rounded-md mt-2 p-4">
              <ul className="space-y-4">
                <li>
                  <a
                    href="#terms"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Termos de Serviço
                  </a>
                </li>
                <li>
                  <a
                    href="#privacy"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Política de Privacidade
                  </a>
                </li>
                <li>
                  <a
                    href="#shipping"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Política de entrega e envio
                  </a>
                </li>
                <li>
                  <a
                    href="#returns"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Política de trocas e devoluções
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Quem Somos?
                  </a>
                </li>
                <li>
                  <a
                    href="#contato"
                    className="block hover:underline"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Contato
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>

        {/* Content Area */}
        <div className="flex-grow p-8 sm:w-3/4 max-w-2xl mx-auto bg-white rounded-lg shadow-md mt-24 sm:mt-32 mb-10">
          <section id="terms" className="mb-8">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Termos de Serviço
                </h1>
                <p className="text-gray-700 mb-6">
                Estes Termos de Serviço regem o uso do nosso site. Ao acessar ou usar
                nosso site, você concorda em cumprir estes termos.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Contas de Usuário
                </h2>
                <p className="text-gray-700 mb-4">
                Para usar certos recursos, você deve criar uma conta. Você é
                responsável por manter a confidencialidade das informações da sua
                conta e da sua senha.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Uso do Site
                </h2>
                <p className="text-gray-700 mb-4">
                Você concorda em usar o site apenas para fins lícitos. Você não pode
                usar o site para distribuir software malicioso, spam ou violar
                direitos de propriedade intelectual.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Produtos/Serviços
                </h2>
                <p className="text-gray-700 mb-4">
                Nosso site oferece itens de marca, como bolsas e óculos. Todas as
                compras estão sujeitas às nossas políticas de devolução e reembolso.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Limitação de Responsabilidade
                </h2>
                <p className="text-gray-700 mb-4">
                Não somos responsáveis por quaisquer danos diretos ou indiretos
                resultantes do uso do nosso site.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Rescisão
                </h2>
                <p className="text-gray-700 mb-4">
                Reservamo-nos o direito de encerrar contas se os usuários violarem
                estes termos.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Alterações aos Termos
                </h2>
                <p className="text-gray-700 mb-4">
                Reservamo-nos o direito de modificar estes termos a qualquer momento.
                As alterações serão publicadas nesta página, e é sua
                responsabilidade revisá-las periodicamente.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Lei Aplicável
                </h2>
                <p className="text-gray-700 mb-4">
                Estes Termos de Serviço são regidos pelas leis do Brasil.
                </p>
          </section>

          {/* Política de Privacidade Section */}
          <section id="privacy" className="mb-8">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Política de Privacidade
                </h1>
                <p className="text-gray-700 mb-6">
                Sua privacidade é importante para nós. Esta Política de Privacidade
                explica como coletamos, usamos, divulgamos e protegemos suas
                informações quando você visita nosso site.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Informações que Coletamos
                </h2>
                <p className="text-gray-700 mb-4">
                Coletamos informações pessoais quando você cria uma conta, incluindo
                seu nome, e-mail e quaisquer dados que você forneça voluntariamente.
                Também usamos cookies para armazenar seus itens na lista de desejos
                e no carrinho.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Como Usamos Suas Informações
                </h2>
                <p className="text-gray-700 mb-4">
                Usamos suas informações para gerenciar sua conta, manter sua lista de
                desejos e dados do carrinho intactos, e melhorar sua experiência em
                nosso site.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Proteção de Dados
                </h2>
                <p className="text-gray-700 mb-4">
                Implementamos medidas de segurança padrão da indústria para proteger
                suas informações pessoais contra acesso, alteração ou destruição
                não autorizados.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Direitos do Usuário
                </h2>
                <p className="text-gray-700 mb-4">
                De acordo com a LGPD, você tem o direito de acessar, corrigir ou
                excluir seus dados pessoais. Você também pode solicitar informações
                sobre como seus dados são processados.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Serviços de Terceiros
                </h2>
                <p className="text-gray-700 mb-4">
                Usamos serviços de terceiros como Mercado Pago para
                processamento de pagamentos, que também cumpre as regulamentações de
                proteção de dados.
                </p>

                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Cookies
                </h2>
                <p className="text-gray-700 mb-4">
                Usamos cookies para armazenar informações essenciais, como itens no
                seu carrinho e lista de desejos. Esses cookies são necessários para a
                funcionalidade básica do nosso site.
                </p>
          </section>

           {/* Política de entrega e envio Section */}
           <section id="shipping" className="mb-8">
              <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Política de entrega e envio
              </h1>
              <p className="text-gray-700 mb-4">
                Todos os produtos serão enviados de acordo com a forma escolhida pelo cliente, em até 2 dias úteis da confirmação do pagamento. É importante ressaltar que a entrega do pedido apenas será realizada após a confirmação do pagamento dentro do próprio Mercado Pago. Em caso de dúvidas, entre em contato com nossos meios de comunicação principais.
              </p>
              <p className="text-gray-700 mb-4">
                O valor do frete da entrega dos produtos é calculado automaticamente pelo site no momento da compra, a partir do peso, volume e quantidade dos produtos adquiridos, assim como da distância entre nosso local de distribuição e o local de entrega informado pelo Cliente.
              </p>
              <p className="text-gray-700 mb-4">
                O prazo para a entrega varia de acordo com a forma de envio escolhida, a partir da postagem do produto a responsabilidade é do Correios. Porém, o Peça Estilosa Brechó Boutique acompanhará a entrega dos seus produtos até a finalização para prestarmos auxílio se necessário. E ainda é importante ressaltar que cada cliente receberá seu devido código de rastreio.
              </p>
              <p className="text-gray-700 mb-4">
                Você será informado do código para rastrear sua encomenda assim que seu pedido for postado. O envio do código de rastreio é feito por e-mail através do contato@pecaestilosabb.com.br.
              </p>
              <p className="text-gray-700 mb-4">
                Todos os nossos produtos devem estar acompanhados da Nota Fiscal no momento do envio. Caso o produto retorne para nós (por motivo de “destinatário ausente” ou semelhante), uma nova taxa de frete será cobrada para reenvio.
              </p>
          </section>

          {/* Política de troca, devoluções e reembolsos Section */}
            <section id="returns" className="mb-8">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Política de troca, devoluções e reembolsos
                </h1>
                <p className="text-gray-700 mb-4">
                O Peça Estilosa Brechó Boutique trabalha com peças únicas, sendo assim, não aceitamos trocas, somente devoluções. Alguns produtos podem apresentar pequenos desgastes de uso. Todos os pequenos desgastes são identificados de forma clara para o comprador através das fotos, vídeos e na descrição de cada item em nosso site.
                </p>
                <p className="text-gray-700 mb-4">
                Toda solicitação de devolução deve ser comunicada ao nosso e-mail contato@pecaestilosabb.com.br dentro do prazo de 7 dias corridos a partir do recebimento do produto de acordo com o código de defesa do consumidor em seu artigo 49. As instruções detalhadas sobre o processo serão enviadas por e-mail ao cliente. O Peça Estilosa comercializa produtos second hand, portanto, não se responsabiliza por produtos após a venda que venham apresentar problemas ou defeitos após 7 dias, a contar da data do recebimento.
                </p>
                <p className="text-gray-700 mb-4">
                É importante ressaltar que a devolução só será possível se a mercadoria estiver nas mesmas condições em que foi entregue.
                </p>
                <p className="text-gray-700 mb-4">
                É de extrema importância que o cliente não retire a etiqueta lacre fixada no produto. Esta informação está descriminada no verso da etiqueta lacre fixada no produto.
                </p>
                <p className="text-gray-700 mb-4">
                Atenção! Ao chegar o produto será analisado sua condição e caso seja constatado uso do produto ou avarias que não foram identificadas no site no momento da compra, a devolução poderá ser recusada, sendo retornado o produto ao cliente.
                </p>
                <p className="text-gray-700 mb-4">
                Realizando a solicitação dentro do prazo de até 7 dias corridos, contados do dia da entrega/recebimento do produto, você pode solicitar a devolução e ter o valor pago devolvido.
                </p>
                <p className="text-gray-700 mb-4">
                O custo do frete para o retorno da mercadoria será de responsabilidade da loja virtual, não tendo custo adicional ao consumidor. Não reembolsamos o custo do frete de envio.
                </p>
                <p className="text-gray-700 mb-4">
                Um código de postagem dos correios será enviado por e-mail. Para devolver o seu produto basta levar o código de postagem anotado até qualquer agência dos correios com o produto devidamente embalado.
                </p>
                <p className="text-gray-700 mb-4">
                Importante que você saiba que não vamos fazer a devolução do valor se não houver uma solicitação de troca registrada antes pelo nosso suporte através do e-mail contato@pecaestilosabb.com.brbb.com.br.com.
                </p>
                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Devolução do valor para Cartão
                </h2>
                <p className="text-gray-700 mb-4">
                Após o recebimento do produto em nossa sede e aprovação da devolução mediante análise dos itens, enviaremos a solicitação de estorno para a administradora do cartão de crédito que está por sua vez tem o prazo para processar a requisição de acordo com a política de cada administradora de cartão de crédito.
                </p>
                <p className="text-gray-700 mb-4">
                Poderá ocorrer do valor estornado ser devolvido em faturas futuras, sendo política da administradora de cartão.
                </p>
                <h2 className="text-xl font-semibold text-gray-800 mt-4">
                Devolução do valor Pix ou Transferência
                </h2>
                <p className="text-gray-700 mb-4">
                A restituição do valor será processada após a aprovação da devolução mediante análise dos itens em nossa sede e será realizada através de reembolso via PIX ou depósito em conta bancária no prazo de até 24 horas.
                </p>
                <p className="text-gray-700 mb-4">
                No momento da solicitação da devolução, deverá ser informado o PIX ou os dados bancários.
                </p>
            </section>

            {/* Sobre Nós Section */}
            <section id="about" className="mb-8">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Quem Somos?
                </h1>

                <p className="text-gray-700 mb-4">
                O Peça Estilosa Brechó Boutique é uma loja online que vende acessórios femininos usados
                em ótimo estado.
                <br/>
                O site conta com produtos seminovos e originais incluindo marcas de luxo. No Peça
                Estilosa, você encontra peças únicas para seu guarda-roupa que foram selecionadas por
                nós com muito carinho e atenção.
                <br/>
                O Peça Estilosa Brechó Boutique nasceu com a proposta de prolongar a vida útil de um
                produto, propiciar a aquisição de peças de qualidade a preços acessíveis e estimular o
                consumo consciente que é fundamental para construirmos um meio ambiente mais
                saudável e sustentável para as futuras gerações.
                </p>
            </section>

            {/* Contato Section */}
            <section id="contato" className="mb-8">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                Contato
                </h1>
                <p className="text-gray-700 mb-4 text-center">
                Caso tenha alguma dúvida ou sugestão, entre em contato conosco pelo e-mail ou Instagram abaixo:
                </p>
                <div className="flex justify-center space-x-6">
                {/* Email Icon */}
                <a href="mailto:contato@pecaestilosabb.com.br" target="_blank" rel="noopener noreferrer">
                    <FiMail className="h-8 w-8 text-gray-700 hover:text-blue-600 transition duration-150" />
                </a>
                {/* Instagram Icon */}
                <a href="https://www.instagram.com/pecaestilosabrechoboutique" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="h-8 w-8 text-gray-700 hover:text-pink-600 transition duration-150" />
                </a>
                </div>
            </section> 
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden sm:block sm:w-1/4 p-6 rounded-lg fixed top-1/4 right-8 transform -translate-y-1/4">
          <ul className="space-y-4">
            <li>
              <a href="#terms" className="hover:underline">
                Termos de Serviço
              </a>
            </li>
            <li>
              <a href="#privacy" className="hover:underline">
                Política de Privacidade
              </a>
            </li>
            <li>
              <a href="#shipping" className="hover:underline">
                Política de entrega e envio
              </a>
            </li>
            <li>
              <a href="#returns" className="hover:underline">
                Política de trocas e devoluções
              </a>
            </li>
            <li>
              <a href="#about" className="hover:underline">
                Quem Somos?
              </a>
            </li>
            <li>
              <a href="#contato" className="hover:underline">
                Contato
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <Footer />
    </div>
  );
};

export default PoliciesPage;
