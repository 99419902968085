import React, { useState } from 'react';
import axios from 'axios';
import authService from './auth_service';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Assuming you have a Header component
import Footer from './Footer'; // Assuming you have a Footer component
import authHeader from './auth-header';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    authService.login(email, password).then(
      async (res) => {
        const userRoles = res.roles;

        if (userRoles.includes('ROLE_CUSTOMER')) {
          const userId = res.id; // Assuming res.id is the user ID

          // Sync wishlist items to backend
          const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
          for (const item of wishlist) {
            try {
              await axios.post(`${process.env.REACT_APP_BACKEND_URL}wishlists`, {
                userId: userId,
                itemId: item.itemId,
              }, { headers: authHeader() });
            } catch (error) {
              console.error(`Erro ao adicionar item ${item.itemId} à lista de desejos:`, error);
            }
          }

          // Sync cart items to backend
          const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
          for (const item of cart) {
            try {
              await axios.post(`${process.env.REACT_APP_BACKEND_URL}carts`, {
                userId: userId,
                itemId: item.itemId,
                quantity: item.quantity, // Assuming item has a quantity property
              }, { headers: authHeader() });
            } catch (error) {
              console.error(`Erro ao adicionar item ${item.itemId} ao carrinho:`, error);
            }
          }

          navigate('/');
        } else if (userRoles.includes('ROLE_ADMIN')) {
          navigate('/admin');
        } else {
          alert("Usuário não possui as permissões necessárias.");
        }
      }
    ).catch((error) => {
      setErrorMessage('Credenciais inválidas. Por favor, tente novamente.');
    });
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Header Component */}
      <Header />

      {/* Main Content: Login Form */}
      <main className="flex-grow flex items-center justify-center bg-[url('./background.png')] bg-cover bg-center bg-no-repeat">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mt-28 mb-16">
          <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
          
          {errorMessage && (
            <p className="text-red-500 text-center mb-4">{errorMessage}</p>
          )}

          <form onSubmit={handleSubmit} >
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 mb-2">Senha</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-gray-700 text-white py-2 rounded-lg hover:bg-black transition-colors"
            >
              Login
            </button>
          </form>

          <p className="text-sm text-center text-gray-600 mt-6">
            Não tem uma conta?{' '}
            <a onClick={()=>{navigate("/signup")}} className="text-blue-500 hover:underline cursor-pointer">
              Cadastre-se
            </a>
          </p>
          <p className="text-sm text-center text-gray-600 mt-1">
            <a onClick={()=>{navigate("/reset-password")}} className="text-blue-500 hover:underline cursor-pointer">
              Esqueceu sua senha?
            </a>
          </p>
        </div>
      </main>

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default Login;
