import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const navigate = useNavigate();

  return (
    <footer className="bg-white text-yellow-600 py-6 border-t-2 border-gray-200 text-center">
      <div className="mb-3">
        <h2 className="text-lg text-black font-semibold mb-2">Entre em Contato</h2>
        <p>
          Email: <a href="mailto:contato@pecaestilosabb.com.br" className="text-black hover:underline">contato@pecaestilosabb.com.br</a>
        </p>
        <p>
          Instagram: <a href="https://www.instagram.com/pecaestilosabrechoboutique" target="_blank" rel="noopener noreferrer" className="text-black hover:underline">@pecaestilosabrechoboutique</a>
        </p>
      </div>
      <div className="mb-3">
        <p>
        <a onClick={() => navigate("/policies#terms")} className="text-black hover:underline cursor-pointer">
          Termos de Serviço
        </a>{" "}
        |{" "}
        <a onClick={() => navigate("/policies#privacy")} className="text-black hover:underline cursor-pointer">
          Política de Privacidade
        </a>{" "}
        |{" "}
        <a onClick={() => navigate("/policies#shipping")} className="text-black hover:underline cursor-pointer">
          Política de entrega e envio
        </a>{" "}
        |{" "}
        <a onClick={() => navigate("/policies#returns")} className="text-black hover:underline cursor-pointer">
          Política de troca, devoluções e reembolsos
        </a>{" "}
        |{" "}
        <a onClick={() => navigate("/policies#about")} className="text-black hover:underline cursor-pointer">
          Quem Somos?
        </a>
        </p>
      </div>
      <p>&copy; 2024 Peça Estilosa Boutique e Brechó. Todos os direitos reservados.</p>
    </footer>
  );
};

export default Footer;
