import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../auth_service';

const AdminHeader = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset;
      if (currentScrollTop > lastScrollTop) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };


  return (
    <>
      <header
        className={`bg-white text-black border-t-2 border-b-2 border-gray-200 flex flex-col items-center pt-2 px-6 fixed top-0 left-0 w-full transition-all duration-300 ease-in-out z-50 ${
          showHeader ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="flex items-center w-full justify-between p-4">
          <h1
            className="text-lg font-bold ml-24 cursor-pointer"
            onClick={() => navigate("/admin")}
          >
            ADMIN
          </h1>
          <nav className="flex items-center ml-36">
            <a onClick={()=>navigate("/admin/brands")} className="text-gray-700 hover:text-gray-900 mr-6 cursor-pointer">
              Brands
            </a>
            <a onClick={()=>navigate("/admin/inventory")} className="text-gray-700 hover:text-gray-900 mr-6 cursor-pointer">
              Inventory
            </a>

            <a onClick={()=>navigate("/admin/email")} className="text-gray-700 hover:text-gray-900 mr-6 cursor-pointer">
              Email
            </a>
          
          </nav>
          <div className="flex space-x-6 ml-auto mr-9 relative">
          <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  >
                    Logout
                  </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default AdminHeader;
