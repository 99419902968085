import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import AdminHeader from "./AdminHeader";
import authHeader from "../auth-header";
import { FaTrashAlt } from 'react-icons/fa';


const Admin = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState(""); // To track selected status
    const ordersPerPage = 6;
    const [orderItems, setOrderItems] = useState([]);
    
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}orders`, { headers: authHeader() });
                setOrders(response.data);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        fetchOrders();
    }, []);

    const filteredOrders = selectedStatus
        ? orders.filter(order => order.status === selectedStatus)
        : orders;

    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredOrders.length / ordersPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

    const setItems = async (items) => {
        try {
            const itemDetailsPromises = items.map(item => 
                axios.get(`${process.env.REACT_APP_BACKEND_URL}items/id=${item.itemId}`, { headers: authHeader() })
            );
            const responses = await Promise.all(itemDetailsPromises);
            const fetchedItems = responses.map(response => response.data);

            const itemsWithQuantity = fetchedItems.map((fetchedItem, index) => ({
                ...fetchedItem,
                quantity: items[index].quantity,
            }));

            setOrderItems(itemsWithQuantity);
        } catch (error) {
            console.error("Error fetching item details:", error);
        }
    };

    const setShipped = async (orderId) => {
        try {
            
            
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}orders/shipped/${orderId}`, {}, { headers: authHeader() });
            console.log("Response:", response);
            
            const updatedOrders = orders.map(order => {
                if (order.orderId === orderId) {
                    return {
                        ...order,
                        status: 'SHIPPED',
                    };
                }
                return order;
            });
            setOrders(updatedOrders);
            setSelectedOrder(null);
        } catch (error) {
            console.error("Error marking order as shipped:", error);
        }
    };

    const setPayed = async (orderId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}orders/payed/${orderId}`, {}, { headers: authHeader() });
            console.log("Response:", response);
            
            const updatedOrders = orders.map(order => {
                if (order.orderId === orderId) {
                    return {
                        ...order,
                        status: 'PAYED',
                    };
                }
                return order;
            });
            setOrders(updatedOrders);
            setSelectedOrder(null);
        } catch (error) {
            console.error("Error marking order as shipped:", error);
        }
    };


    const handleDelete = async (orderId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}orders/${orderId}`, { headers: authHeader() });
            const updatedOrders = orders.filter(order => order.orderId !== orderId);
            setOrders(updatedOrders);
            if (selectedOrder?.orderId === orderId){
            setSelectedOrder(null);
            }
        } catch (error) {
            alert("Error deleting order:", error);
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <AdminHeader />

            <div className="flex-grow p-6 mt-24">
                <div className="flex justify-between">
                    <div className="w-1/2">
                        <div className="overflow-x-auto bg-slate-200 p-7 rounded">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Orders</h2>

                                {/* Add the status filter */}
                                <select
                                    className="ml-4 px-3 py-2 border border-gray-300 rounded"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="CANCELLED">Cancelled</option>
                                    <option value="SHIPPED">Shipped</option>
                                    <option value="PAYED">Paid</option>
                                    <option value="PENDING">Pending</option>
                                    <option value="PROCESSING">PROCESSING</option>
                                </select>
                            </div>
                            
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Order ID</th>
                                        <th className="py-2 px-4 border-b">Customer</th>
                                        <th className="py-2 px-4 border-b">Status</th>
                                        <th className="py-2 px-4 border-b">City</th>
                                        <th className="py-2 px-4 border-b">Email</th>
                                        <th className="py-2 px-4 border-b">Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentOrders.map((order) => (
                                        <tr
                                            key={order.orderId}
                                            onClick={() => {
                                                setSelectedOrder(order);
                                                setItems(order.items);
                                            }}
                                            className="cursor-pointer hover:bg-gray-100"
                                        >
                                            <td className="py-2 px-4 border-b">{order.orderId}</td>
                                            <td className="py-2 px-4 border-b">{`${order.firstName} ${order.lastName}`}</td>
                                            
                                            {/* Conditional rendering for status */}
                                            <td className="py-2 px-4 border-b">
                                                <span
                                                    className={`px-2 py-1 rounded font-bold text-white ${
                                                        order.status === 'CANCELLED' 
                                                            ? ' bg-red-400 text-red-700' 
                                                            : order.status === 'SHIPPED'
                                                            ? 'bg-blue-400 text-blue-700' 
                                                            : order.status === 'PAYED' 
                                                            ? 'bg-green-400 text-green-700'
                                                            : 'bg-yellow-400 text-yellow-700'
                                                    }`}
                                                >
                                                    {order.status}
                                                </span>
                                            </td>
                                            
                                            <td className="py-2 px-4 border-b">{order.city}</td>
                                            <td className="py-2 px-4 border-b">{order.email}</td>
                                            <td className="py-2 px-4 border-b">{order.phoneNumber}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                            <div className="flex justify-between mt-4 items-center">
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Previous
                                </button>

                                <div>
                                    Page {currentPage} of {totalPages}
                                </div>

                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage >= totalPages}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage >= totalPages ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 p-6 bg-white rounded shadow-md flex flex-col items-center">
                        {selectedOrder ? (
                            <div>

                                <div className=" text-center">
                                <h3 className="text-xl font-bold mb-4 flex justify-between items-center">
                                    <span>Order #{selectedOrder.orderId}</span>
                                    <FaTrashAlt
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => handleDelete(selectedOrder.orderId)}
                                    />
                                </h3>

                                </div>
                                <div className=" text-left">
                                <h3 className="text-lg font-bold mb-4">Customer Information</h3>
                                <p><strong>Customer:</strong> {`${selectedOrder.firstName} ${selectedOrder.lastName}`}</p>
                                <p><strong>Email:</strong> {selectedOrder.email}</p>
                                <p><strong>Phone:</strong> {selectedOrder.phoneNumber}</p>
                                <p><strong>CPF:</strong> {selectedOrder.cpf}</p>

                                </div>
                                <div className=" text-left">
                                <h3 className="text-lg font-bold mb-4 mt-8">Order Information</h3>
                                <p className="mb-2">
                                    <strong>Status:</strong>
                                    <span
                                        className={`ml-2 px-2 py-1 rounded font-bold text-white ${
                                            selectedOrder.status === 'CANCELLED' 
                                                ? ' bg-red-400 text-red-700' 
                                                : selectedOrder.status === 'SHIPPED'
                                                ? 'bg-blue-400 text-blue-700' 
                                                : selectedOrder.status === 'PAYED' 
                                                ? 'bg-green-400 text-green-700'
                                                : 'bg-yellow-400 text-yellow-700'
                                        }`}
                                    >
                                        {selectedOrder.status}
                                    </span>
                                </p>
                                <p><strong>Date:</strong> {new Date(selectedOrder.date).toLocaleDateString()}</p>
                                <p><strong>Shipping Cost:</strong> R$ {selectedOrder.shippingCost}  ({selectedOrder.shippingMethod})</p>
                                <p><strong>Total:</strong> R$ {selectedOrder.totalAmount}</p>
                                <p><strong>Preference ID (Mercado Pago):</strong> {selectedOrder.preferenceId}</p>
                                <p><strong>Address:</strong> {`${selectedOrder.number}, ${selectedOrder.street}, ${selectedOrder.district}, ${selectedOrder.city}, ${selectedOrder.state} , CEP: ${selectedOrder.cep}`}</p>
                                </div>
                                <div className=" text-left">
                                <h3 className="text-lg font-bold mb-4 mt-8">Items</h3>
                                <ul>
                                    {orderItems.map((item) => (
                                        <li key={item.itemId}>
                                            <img src={item.frontImage} alt={item.name} className="w-32 h-32 rounded inline-block" />
                                              <span className="ml-10">{item.name} </span> <span className="ml-10">Quantity:{item.quantity}</span> <span className="ml-10">Preço por unidade:  R${parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></li>
                                    ))}
                                </ul>
                                </div>
                                {selectedOrder.status === 'PAYED' ? (
                                    <div className="mt-8">
                                        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={()=> setShipped(selectedOrder.orderId)}>
                                            Mark as shipped
                                        </button>
                                    </div>
                                ) : null}

                                {selectedOrder.status === 'PENDING' || selectedOrder.status === 'PROCESSING' ? (
                                    <div className="mt-8">
                                        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={()=> setPayed(selectedOrder.orderId)}>
                                            Mark as payed
                                        </button>
                                    </div>
                                ) : null}
                              
                            </div>
                        ) : (
                            <p className="text-gray-500">Select an order to see the details.</p>
                        )}
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
};

export default Admin;
