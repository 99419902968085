import React, { useState } from 'react';
import Header from './Header.js';
import sha256 from 'crypto-js/sha256';
import { useNavigate, useParams } from 'react-router-dom';
import authService from './auth_service.js';
import Footer from './Footer.js';

function ResetPassword() {
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); // New state for error messages
    const [successMessage, setSuccessMessage] = useState(""); // New state for success messages
    const navigate = useNavigate();

    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    function handleResetPasswordRequest(event) {
        event.preventDefault();
        
        if (password !== passwordConfirmation) {
            setErrorMessage("As senhas não correspondem.");
            return;
        }

        if (!isValidPassword(password)) {
            setErrorMessage("A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.");
            return;
        }

        sessionStorage.setItem('token', token);
        const hashedPassword = sha256(password).toString();

        authService.resetPassword(hashedPassword)
        .then(response => {
            if(response.status === 200) {
                setSuccessMessage("Redefinição de senha bem-sucedida.");
                setErrorMessage(""); // Clear any previous error messages
                setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
            }
        })
        .catch(error => {
            if (error.response.data) {
                setErrorMessage("Erro: " + error.response.data);
            } else {
                setErrorMessage("Ocorreu um erro inesperado.");
            }
        });
    }

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
          <Header />
          <main className="flex-grow flex items-center justify-center bg-[url('./background.png')] bg-cover bg-center bg-no-repeat">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mt-10">
              <h1 className="text-2xl font-semibold text-center mb-6">Redefinir Senha</h1>

              {/* Display error or success messages */}
              {errorMessage && (
                <p className="text-red-500 text-center mb-4">{errorMessage}</p>
              )}
              {successMessage && (
                <p className="text-green-500 text-center mb-4">{successMessage}</p>
              )}

              <form onSubmit={handleResetPasswordRequest}>
                <input
                  className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="password"
                  placeholder="Digite sua nova senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input
                  className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="password"
                  placeholder="Confirme sua nova senha"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
                <button
                  className="w-full bg-gray-700 text-white py-2 rounded-lg hover:bg-black transition-colors"
                  type="submit"
                >
                  Redefinir Senha
                </button>
              </form>
            </div>
          </main>
          <Footer />
        </div>
    );
}

export default ResetPassword;
