import axios from "axios";
import { useState, useEffect } from "react";

const CartItemMini = ({ item, onDelete }) => {

  const [thisItem, setItem] = useState(item);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  
  const handleDelete = () => {
    isUserLoggedIn ? onDelete(item.cartItemId) : onDelete(item.itemId);
  };
 
  useEffect(() => {
    const userString = sessionStorage.getItem('user');
    if (userString) {
      setIsUserLoggedIn(true);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}items/id=${item.itemId}`)
        .then(response => {
          setItem({ ...response.data, quantity: item.quantity });
        })
        .catch(error => {
          console.error('Error fetching item details:', error);
        });
    } else {
      setItem(item);
    }
  }, [item]);


  return (
    <div className="relative flex items-center justify-between border-b pb-2">
      {/* Delete button */}
      <button
        onClick={handleDelete}
        className="absolute top-0 right-0 text-red-500 hover:text-red-700 text-xl font-bold p-2"
        style={{ transform: 'translate(50%, -50%)' }} // Adjust the position to ensure it stays top-right
      >
        &times; {/* The X symbol */}
      </button>

      <div className="flex items-center">
        <img
          src={thisItem.frontImage}
          alt={thisItem.name}
          className="w-16 h-16 object-cover rounded-md mr-4"
        />
        <div>
          <h3 className="text-lg font-medium">{thisItem.name}</h3>
          <p className="text-sm text-gray-600">Quantidade: {thisItem.quantity}</p>
        </div>
      </div>
      <p className="text-lg font-semibold">R${parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
    </div>
  );
};

export default CartItemMini;