import { TruckIcon, ShieldCheckIcon, CreditCardIcon } from '@heroicons/react/solid';
import { FaInstagram } from 'react-icons/fa';

const PromotionBar = () => {
  return (
    <section className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-2 w-full z-40 shadow-md mt-24">
      <div className="flex flex-col sm:flex-row sm:space-x-4 items-center text-sm px-4 md:px-10 lg:px-24 space-y-2 sm:space-y-0 text-center sm:text-left justify-center">
        
        {/* Instagram Link */}
        <a href="https://www.instagram.com/pecaestilosabrechoboutique" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-1 hover:text-yellow-300">
          <FaInstagram className="w-5 h-5" />
          <span>Visite nosso perfil Instagram @pecaestilosa</span>
        </a>

        {/* Divider for larger screens */}
        <span className="hidden sm:block sm:mx-4 border-l-2 border-white h-5"></span>

        {/* Shipping Information */}
        <div className="flex items-center space-x-1 hover:text-yellow-300">
          <TruckIcon className="w-5 h-5" />
          <span>Enviamos para todo o Brasil</span>
        </div>

        {/* Divider for larger screens */}
        <span className="hidden sm:block sm:mx-4 border-l-2 border-white h-5"></span>

        {/* Secure Payment */}
        <div className="flex items-center space-x-1 hover:text-yellow-300">
          <CreditCardIcon className="w-5 h-5" />
          <span>Pagamento facilitado com parcelamento</span>
        </div>

        {/* Divider for larger screens */}
        <span className="hidden sm:block sm:mx-4 border-l-2 border-white h-5"></span>

        {/* Security Information */}
        <div className="flex items-center space-x-1 hover:text-yellow-300">
          <ShieldCheckIcon className="w-5 h-5" />
          <span>Compra 100% segura</span>
        </div>
      </div>
    </section>
  );
};

export default PromotionBar;
