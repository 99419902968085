import Footer from "./Footer"
import Header from "./Header"
import { FaCheckCircle } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from 'axios';

const Success = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(10);  // Timer set to 10 seconds

    useEffect(() => {
        sessionStorage.removeItem('cart');

        

        axios.put(`${process.env.REACT_APP_BACKEND_URL}orders/success/${orderId}`) 
        .catch(error => {
            alert('Error setting order to pending:', error);
            navigate('/');
        })

        // Start the countdown
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        // Redirect after 10 seconds
        const timeout = setTimeout(() => {
            navigate('/');
        }, 10000);

        // Clean up timers on component unmount
        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };
    }, [orderId, navigate]);

    return (
        <div>
            <Header />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4 rounded-lg">
                <FaCheckCircle className="text-green-500 text-6xl mb-6" />
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Compra Realizada com Sucesso!</h1>
                <p className="text-lg text-gray-600 mb-6">Obrigado pela sua compra. Agradecemos a confiança!</p>
                <p className="text-lg text-gray-600 mb-6">(#compra {orderId})</p>
                <p className="text-lg text-gray-600 mb-6">
                    Redirecionando em {countdown} segundos...
                </p>
            
            </div>
            <Footer />
        </div>
    );
}

export default Success;
