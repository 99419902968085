import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Assuming you have a Header component
import Footer from './Footer'; // Assuming you have a Footer component
import sha256 from 'crypto-js/sha256';
import InputMask from 'react-input-mask'; // Importing input mask

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [wantEmail, setWantEmail] = useState(false);
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  var [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('As senhas não correspondem.');
      return;
    }

    if (!isValidPassword(password)) {
      setErrorMessage('A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.');
      return;
    }

    if (phoneNumber.replace(/\D/g, '').length !== 11) {
      setErrorMessage('O número de telefone deve ter 11 dígitos, incluindo o código de área.');
      return;
    }

    const hashedPassword = sha256(password).toString();
    password = hashedPassword;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}auth/signup`, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        wantEmail
      });

      setSuccessMessage('Conta criada com sucesso! Você já pode fazer login.');
      setErrorMessage('');
      setTimeout(() => navigate('/login'), 2000); // Redireciona para a tela de login após 2 segundos
    } catch (error) {
      setErrorMessage('Erro ao criar conta. Por favor, tente novamente.');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Header Component */}
      <Header />

      {/* Main Content: SignUp Form */}
      <main className="flex-grow flex items-center justify-center bg-[url('./background.png')] bg-cover bg-center bg-no-repeat">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg mt-32 mb-10">
          <h2 className="text-2xl font-semibold text-center mb-6">Criar Conta</h2>

          {errorMessage && (
            <p className="text-red-500 text-center mb-4">{errorMessage}</p>
          )}

          {successMessage && (
            <p className="text-green-500 text-center mb-4">{successMessage}</p>
          )}

          <form onSubmit={handleSubmit}>
            {/* First Name and Last Name on Same Row */}
            <div className="flex flex-row gap-x-4 mb-4">
              <div className="w-1/2 min-w-0 pr-9">
                <label htmlFor="firstName" className="block text-gray-700 mb-2">Nome</label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="w-1/2 min-w-0">
                <label htmlFor="lastName" className="block text-gray-700 mb-2">Sobrenome</label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Email */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Phone Number with Input Mask */}
            <div className="mb-4">
              <label htmlFor="tel" className="block text-gray-700 mb-2">Número de Telefone</label>
              <InputMask
                mask="(99) 99999-9999"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Password */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 mb-2">Senha</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Mínimo de 8 caracteres, com maiúsculas, minúsculas e símbolos."
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Confirm Password */}
            <div className="mb-6">
              <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirmar Senha</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Checkbox for Want Email */}
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="wantEmail"
                checked={wantEmail}
                onChange={(e) => setWantEmail(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="wantEmail" className="text-gray-700">Desejo receber e-mails sobre novidades e promoções.</label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gray-700 text-white py-2 rounded-lg hover:bg-black transition-colors"
            >
              Criar Conta
            </button>
          </form>

          <p className="text-sm text-center text-gray-600 mt-6">
            Já tem uma conta?{' '}
            <a onClick={() => { navigate("/login") }} className="text-blue-500 hover:underline cursor-pointer">
              Entrar
            </a>
          </p>
        </div>
      </main>

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default SignUp;
