import React from 'react';
import LandingPage from './LandingPage';
import { RouterProvider, createBrowserRouter} from 'react-router-dom';
import Item from './Item';
import Login from './Login';
import SignUp from './SignUp';
import Wishlist from './WishlistPage';
import CartPage from './CartPage';
import Admin from './Admin/Admin';
import Brands from './Admin/Brands';
import Success from './Success';
import Cancelled from './Cancelled';
import Inventory from './Admin/Inventory';
import Pending from './Pending';
import PurchaseHistory from './PurchaseHistory';
import ResetPassword from './ResetPassword';
import ResetPasswordRequest from './ResetPasswordRequest';
import Email from './Admin/Email';
import PoliciesPage from './PoliciesPage';

const router = createBrowserRouter([
  {
    children  :[
      {
        path : "/",
        element: <LandingPage/>
      },
      {
        path : "/items/:itemId",
        element: <Item/>
      },
      {
        path : "/brand/:brand",
        element: <LandingPage/>
      },
      {
        path : "/login",
        element: <Login/>
      },
      {
        path : "/signup",
        element: <SignUp/>
      },
      {
        path : "/wishlist",
        element: <Wishlist/>
      },
      {
        path : "/cart",
        element: <CartPage/>
      },
      {
        path : "/admin",
        element: <Admin/>
      },
      {
        path : "/admin/brands",
        element: <Brands/>
      },
      {
        path : "/success/:orderId",
        element: <Success/>
      },
      {
        path : "/cancelled/:orderId",
        element: <Cancelled/>
      },
      {
        path : "/pending/:orderId",
        element: <Pending/>
      }
      ,
      {
        path: "/admin/inventory",
        element: <Inventory/>
      },
      {
        path: "/admin/email",
        element: <Email/>
      }
      ,
      {
        path : "/purchase-history",
        element: <PurchaseHistory/>
      }
      ,
      {
        path : "*",
        element: <LandingPage/> 
      },
      {
        path: "/reset-password",
        element: <ResetPasswordRequest/>
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword/>
      },
      {
        path: "/policies",
        element: <PoliciesPage/>
      }
    ]
  }
]);

function App() {
  return (
    <div>
        <RouterProvider router={router}/>
    </div>
  );
}

export default App;
