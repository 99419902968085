import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ItemCard from './ItemCard';
import { useParams, useNavigate } from 'react-router-dom';
import './LandingPage.css';
import PromotionBar from './Promotion';

const LandingPage = () => {
  const [items, setItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000); // Default max price
  const [priceRange, setPriceRange] = useState([0, 1000]); // To store the min-max price range of items
  const { brand } = useParams();
  const navigate = useNavigate();
  const brandFromUrl = brand;

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const endpoint = brand 
          ? `${process.env.REACT_APP_BACKEND_URL}items/b=${brand}`
          : `${process.env.REACT_APP_BACKEND_URL}items`;
        
        const response = await axios.get(endpoint);
        setItems(response.data);

        // Determine the min-max price range from items
        const prices = response.data.map(item => item.actualPrice);
        const minItemPrice = Math.min(...prices);
        const maxItemPrice = Math.max(...prices);
        
        setPriceRange([minItemPrice, maxItemPrice]);
        setMinPrice(minItemPrice);
        setMaxPrice(maxItemPrice);

        const initialImageIndex = {};
        response.data.forEach(item => {
          initialImageIndex[item.itemId] = 'left';
        });
        setCurrentImageIndex(initialImageIndex);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}brands`);
        setBrands(response.data);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchItems();
    fetchBrands();
  }, [brand]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => {
        const updatedIndex = {};
        items.forEach(item => {
          updatedIndex[item.itemId] = prevIndex[item.itemId] === 'left' ? 'right' : 'left';
        });
        return updatedIndex;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [items]);

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
  };

  const handleBrandChange = (brandName) => {
    if (selectedBrands.includes(brandName)) {
      if (brandFromUrl === brandName) {
        navigate('/');
      }
      setSelectedBrands(prev => prev.filter(b => b !== brandName));
    } else {
      setSelectedBrands(prev => [...prev, brandName]);
    }
  };

  const handlePriceChange = (e, type) => {
    const value = Number(e.target.value);
    if (type === 'min') {
      setMinPrice(value);
    } else {
      setMaxPrice(value);
    }
  };

  const filteredItems = items.filter(item => {
    const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(item.category);
    const brandMatch = selectedBrands.length === 0 || selectedBrands.includes(item.brand);
    
    // Price filter
    const priceMatch = item.actualPrice >= minPrice && item.actualPrice <= maxPrice;

    return categoryMatch && brandMatch && priceMatch;
  });

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header />
      <PromotionBar />
      {/* Main content with background image */}
      <main
        className="flex-grow bg-[url('./bg2.png')]  bg-center bg-no-repeat"
      >
        <section id="shop" className="py-20">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
              <aside className="lg:col-span-1 bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">Filtro</h3>
  
                <div className="mb-6">
                  <h4 className="font-semibold text-gray-700 mb-2">Categorias</h4>
                  <div className="grid grid-cols-3 gap-3 sm:grid-cols-1">
                    <label className="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={selectedCategories.includes('BOLSAS')}
                        onChange={() => handleCategoryChange('BOLSAS')}
                      />
                      <span className="ml-2">Bolsas</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={selectedCategories.includes('OCULOS')}
                        onChange={() => handleCategoryChange('OCULOS')}
                      />
                      <span className="ml-2">Óculos</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={selectedCategories.includes('MAQUIAGEM')}
                        onChange={() => handleCategoryChange('MAQUIAGEM')}
                      />
                      <span className="ml-2">Maquiagem</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={selectedCategories.includes('PERFUMARIA')}
                        onChange={() => handleCategoryChange('PERFUMARIA')}
                      />
                      <span className="ml-2">Perfumaria</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={selectedCategories.includes('CALCADOS')}
                        onChange={() => handleCategoryChange('CALCADOS')}
                      />
                      <span className="ml-2">Calçados</span>
                    </label>
                  </div>
                </div>
                <div className="mb-6">
                  <h4 className="font-semibold text-gray-700 mb-2">Marcas</h4>
                  <div className="grid grid-cols-3 gap-3 sm:grid-cols-1">
                    {brands.map((brand) => (
                      <label key={brand.brandId} className="flex items-center space-x-2">
                        <input 
                          type="checkbox" 
                          className="form-checkbox"
                          checked={selectedBrands.includes(brand.name)}
                          onChange={() => handleBrandChange(brand.name)}
                        />
                        <span>{brand.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
                {/* Price Filter */}
                <div className="mb-6">
                  <h4 className="font-semibold text-gray-700 mb-2">Preço</h4>
                  <div className="flex flex-col space-y-4">
                    <label>Preço Mínimo: R$ {minPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</label>
                    <input
                      type="range"
                      min={priceRange[0] - 10}
                      max={priceRange[1] + 10}
                      value={minPrice}
                      onChange={(e) => handlePriceChange(e, 'min')}
                      className="w-full"
                    />
                    <label>Preço Máximo: R$ {maxPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</label>
                    <input
                      type="range"
                      min={priceRange[0] - 10}
                      max={priceRange[1] + 10}
                      value={maxPrice}
                      onChange={(e) => handlePriceChange(e, 'max')}
                      className="w-full"
                    />
                  </div>
                </div>

  
              </aside>
  
              <div className="lg:col-span-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ml-10">
                  {filteredItems.map(item => (
                    <ItemCard
                      key={item.itemId}
                      item={item}
                      currentImageIndex={currentImageIndex[item.itemId]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  
      {/* Footer */}
      <Footer />
    </div>
  );
  
};

export default LandingPage;
