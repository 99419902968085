import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import authHeader from './auth-header';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'

const PurchaseHistory = () => {
    const [orders, setOrders] = useState([]);
    const [itemsMap, setItemsMap] = useState({});

    useEffect(() => {
        const initializeMercadoPago = async () => {
        try {
        initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY)
        }
        catch (error) {
          console.error('Error initializing Mercado Pago:', error);
        }
    
      }
      initializeMercadoPago();
      }, [])

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userId = user.id;

        axios.get(`${process.env.REACT_APP_BACKEND_URL}orders/user/` + userId, { headers: authHeader() })
            .then(response => {
                setOrders(response.data);
                fetchItemsForOrders(response.data); // Fetch items for each order
            });
    }, []);

    const getStatusInPortuguese = (status) => {
        switch (status) {
          case 'CANCELLED':
            return 'CANCELADO';
          case 'SHIPPED':
            return 'ENVIADO';
          case 'PAYED':
            return 'PAGO';
          case 'PROCESSING':
            return 'EM PROCESSAMENTO';
          case 'PENDING':
            return 'PENDENTE';
          default:
            return status;
        }
      };

      
    const fetchItemsForOrders = async (orders) => {
        const itemsMapCopy = {};

        for (const order of orders) {
            const items = await fetchItems(order.items);
            itemsMapCopy[order.orderId] = items; // Store items by orderId
        }

        setItemsMap(itemsMapCopy);
    };

    const fetchItems = async (itemsInput) => {
        const items = await Promise.all(itemsInput.map(async item => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items/id=` + item.itemId);
            const itemData = response.data;
            itemData.quantity = item.quantity;
            return itemData;
        }));
        return items;
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <div className="flex-grow container mx-auto p-4 mt-16">
                <h1 className="text-4xl font-bold text-gray-800 mb-8 mt-20">Histórico de Compras</h1>
                <div className="space-y-8">
                    {orders.map(order => (
                        <div key={order.id} className="bg-white shadow-lg rounded-lg p-6 md:flex md:justify-between">
                            {/* Left: Order Information */}
                            <div className="mb-4 md:mb-0">
                                <h2 className="text-2xl text-gray-800 font-semibold mb-2">
                                    Pedido (<span className='text-sm text-gray-500'>#{order.status === 'PROCESSING' || order.status === 'PENDING' ? '' : order.orderId}</span>)
                                </h2>
                                <p className="text-gray-600">Data: {new Date(order.date).toLocaleDateString()}</p>
                                <p className="text-gray-600">Frete: <span className="text-yellow-600 font-semibold">R$ {order.shippingCost}   ({order.shippingMethod})</span></p>
                                <p className="text-gray-600">Total: <span className="text-green-600 font-semibold">R$ {order.totalAmount}</span></p>
                                <p className="text-gray-600">Status: <span className="text-blue-500">{getStatusInPortuguese(order.status)}</span></p>
                                <p className="text-gray-600">
                                    Endereço de Entrega: {`${order.number}, ${order.city} ${order.district}, ${order.state}, ${order.country}, CEP: ${order.cep}`}
                                </p>
                                {order.status === 'PROCESSING' ? (
                                    <Wallet
                                    initialization={{ preferenceId: order.preferenceId }}
                                    customization={{ texts:{ valueProp: 'smart_option'}}}
                                    />
                                ) : null}

                                
                            </div>

                            {/* Right: Order Items */}
                            <div className="md:w-1/2 flex flex-col items-end">
                                {itemsMap[order.orderId] && itemsMap[order.orderId].map(item => (
                                    <div key={item.id} className="flex items-center mb-4 w-full">
                                        <img src={item.frontImage} alt={item.name} className="w-20 h-20 object-cover rounded-lg shadow-md" />
                                        <div className="ml-4">
                                            <h3 className="text-lg font-medium text-gray-800">{item.name}</h3>
                                            <p className="text-sm text-gray-600">Quantidade: {item.quantity}</p>
                                            <p className="text-sm text-gray-600">Preço: R${parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PurchaseHistory;
