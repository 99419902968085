import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ItemCard from './ItemCard';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from './auth-header';
import PromotionBar from './Promotion';

const WishlistPage = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWishlistItems = async () => {
      const userString = sessionStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        const userId = user.id;
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}wishlists/${userId}`);
          const items = response.data;
          setWishlistItems(items);
          setCount(items.length);

          const itemRequests = items.map(item =>
            axios.get(`${process.env.REACT_APP_BACKEND_URL}items/id=${item.itemId}`)
              .then(response => ({ ...response.data, wishlistId: item.wishListId }))
          );

          const itemDetails = await Promise.all(itemRequests);
          setItemDetails(itemDetails);
        } catch (error) {
          console.error('Error fetching wishlist items:', error);
        }
      } else {
        const wishlistFromSession = sessionStorage.getItem('wishlist');
        if (wishlistFromSession) {
          const items = JSON.parse(wishlistFromSession);
          setWishlistItems(items);
          setCount(items.length);

          const itemRequests = items.map(item =>
            axios.get(`${process.env.REACT_APP_BACKEND_URL}items/${item.itemId}`)
              .then(response => ({ ...response.data, wishlistId: item.wishListId }))
          );

          const itemDetails = await Promise.all(itemRequests);
          setItemDetails(itemDetails);
        } else {
          console.log('No wishlist found in session storage');
        }
      }
    };

    fetchWishlistItems();
  }, []);

  const handleRemoveFromWishlist = (wishlistId) => {
    setWishlistItems((prevItems) => prevItems.filter((item) => item.itemId !== wishlistId));
    setItemDetails((prevDetails) => prevDetails.filter((item) => item.itemId !== wishlistId));
  
    setCount((prevCount) => {
      const newCount = prevCount - 1;
      if (newCount === 0) {
        navigate('/');
      }
      return newCount;
    });
  };

  const addToCart = async (item) => {
    const userString = sessionStorage.getItem('user');
    
    if (userString) {
      const user = JSON.parse(userString);
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}carts`, {
          userId: user.id,
          itemId: item.itemId,
        }, { headers: authHeader() });
        toast.success('Item added to cart successfully!');
      } catch (error) {
        console.error('Error adding item to cart:', error);
        toast.info(error.response.data.message);
      }
    } else {
      const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
      const existingItemIndex = cart.findIndex(cartItem => cartItem.itemId === item.itemId);
  
      if (existingItemIndex > -1) {
        cart[existingItemIndex].quantity += 1;
      } else {
        cart.push({ ...item, quantity: 1 });
      }
  
      sessionStorage.setItem('cart', JSON.stringify(cart));
      toast.success('Item added to cart!');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <PromotionBar />
      <ToastContainer position="bottom-right" autoClose={3000} />
      <section id="wishlist" className="flex-grow py-20 bg-[url('./bg2.png')] bg-center bg-no-repeat">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-semibold text-gray-700 pb-10">
            Lista de desejos ({count})
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-fit">
            {itemDetails.map(item => (
              <div key={item.itemId} className="flex flex-col items-center">
                <ItemCard
                  item={item}
                  currentImageIndex="left"
                  onRemove={handleRemoveFromWishlist}
                />
                {item.stock > 0 ? (
                  <button className="py-2 px-6 bg-black text-white font-semibold hover:bg-gray-800 transition self-stretch mt-4" onClick={() => addToCart(item)}>
                    Add to Cart
                  </button>
                ) : (
                  <button className="py-2 px-6 bg-gray-400 text-white font-semibold self-stretch mt-4" disabled>
                    Out of Stock
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default WishlistPage;
