import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import Footer from "../Footer";
import authHeader from "../auth-header";

const Brands = () => {
    const [brands, setBrands] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentBrands, setCurrentBrands] = useState([]);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        const fetchBrands = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}brands`);
            setBrands(response.data);
            updatePaginatedBrands(response.data, currentPage);
        };
        fetchBrands();
    }, [currentPage]);

    const updatePaginatedBrands = (brandsData, page) => {
        setTotalPages(Math.ceil(brandsData.length / 10));
        const startIndex = (page - 1) * 10;
        const endIndex = startIndex + 10;
        setCurrentBrands(brandsData.slice(startIndex, endIndex));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleAddBrand = async (event) => {
        event.preventDefault();
        const form = event.target;

        try {
            // Get form data
            const data = new FormData(form);
            const name = data.get('name');
            const country = data.get('country');

            // Post the new brand data
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}brands`, {
                name,
                country
            }, { headers: authHeader() });

            // Reset the form
            form.reset();

            // Fetch the updated brand list
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}brands`);
            setBrands(response.data);

            // Update the paginated brands
            updatePaginatedBrands(response.data, 1);

            // Resetting the form state and current page after successful submission
            setIsAdding(false);
            setCurrentPage(1);
        } catch (error) {
            console.error("Error adding brand:", error);
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <AdminHeader />

            <div className="flex-grow p-6 mt-24">
                <div className="flex justify-between">
                    <div className="w-1/2">
                        <div className="overflow-x-auto bg-slate-200 p-7 rounded">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Brands</h2>
                                <button 
                                    onClick={() => setIsAdding(true)} 
                                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                >
                                    Add
                                </button>
                            </div>
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Name</th>
                                        <th className="py-2 px-4 border-b">Country</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentBrands.map((brand) => (
                                        <tr
                                            key={brand.brandId}
                                            className="cursor-pointer hover:bg-gray-100"
                                        >
                                            <td className="py-2 px-4 border-b">{brand.name}</td>
                                            <td className="py-2 px-4 border-b">{brand.country}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-between mt-4 items-center">
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Previous
                                </button>

                                <div>
                                    Page {currentPage} of {totalPages}
                                </div>

                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage >= totalPages}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage >= totalPages ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                    {isAdding && (
                        <div className="w-1/3 p-6 bg-white rounded shadow-md flex flex-col items-center justify-center mx-auto">
                            <form className="w-full" onSubmit={handleAddBrand}>

                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <input
                                        name="name"
                                        type="text"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <label className="block text-sm font-medium text-gray-700">
                                        Country
                                    </label>
                                    <input
                                        name="country"
                                        type="text"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>

                                <div className="flex justify-between">
                                    <button
                                        onClick={() => setIsAdding(false)}
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Brands;
