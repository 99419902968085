import React, { useState } from 'react';

const CartComponentItem = ({ item, updateQuantity, removeItem }) => {
  const [isEditing, setIsEditing] = useState(false); // Estado para acompanhar se a quantidade está sendo editada
  const [quantity, setQuantity] = useState(item.quantity); // Estado para armazenar a quantidade atualizada

  const handleEditClick = () => {
    setIsEditing(true); // Habilita o modo de edição
  };

  const handleConfirmClick = () => {
    updateQuantity(item.cartId, quantity); // Atualiza a quantidade
    setIsEditing(false); // Desabilita o modo de edição após confirmar as alterações
  };

  return (
    <div className="relative flex flex-col sm:flex-row items-center justify-between py-4 border-b border-gray-200">
      <div className="flex items-center space-x-4">
        <img
          src={item.frontImage}
          alt={item.name}
          className="w-40 h-40 sm:w-60 sm:h-60 object-contain"
        />
        <div>
          <h2 className="text-lg font-semibold text-center sm:text-left">{item.name}</h2>
          <p className="text-gray-600 text-center sm:text-left">{item.brand}</p>
          <p className="text-gray-600 text-center sm:text-left">
            Preço: R${parseFloat(item.actualPrice).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>

          {/* Exibir Quantidade e Botão de Editar */}
          <p className="text-gray-600 text-center sm:text-left">
            Quantidade:
            {isEditing ? (
              <span className="ml-2">
                <input
                  type="number"
                  value={quantity}
                  min="1"
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                  className="ml-2 w-12 border border-gray-300 rounded text-center"
                />
                <button
                  className="ml-2 text-blue-600 hover:underline"
                  onClick={handleConfirmClick}
                >
                  Confirmar
                </button>
              </span>
            ) : (
              <>
                <span className="ml-2">{item.quantity}</span>
              </>
            )}
          </p>
        </div>
      </div>

      {/* Botões para dispositivos móveis e desktop */}
      <div className="flex flex-col sm:flex-row sm:space-x-4 items-center mt-4 sm:mt-0">
        {!isEditing && (
          <button
            className="text-black underline hover:text-gray-800 mb-2 sm:mb-0"
            onClick={handleEditClick}
          >
            Editar
          </button>
        )}

        <button
          className="text-red-600 hover:text-red-800"
          onClick={() => removeItem(item.cartId)}
        >
          Remover
        </button>
      </div>
    </div>
  );
};

export default CartComponentItem;
