import axios from 'axios';
import authHeader from './auth-header';
import sha256 from 'crypto-js/sha256';

const API_URL = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api/v1/'}auth/`;

class AuthService {
    googleLogin(token){
        return axios.post(`${API_URL}google-login/${token}`)
            .then(this.handleResponse);
    }

    login(email, password){
        password = sha256(password).toString();
        return axios
            .post(`${API_URL}login`, { email, password })
            .then(this.handleResponse);
    }

    logout(){
        sessionStorage.clear();
    }

    register(firstName, lastName, phoneNumber, email, password){
        password = sha256(password).toString();
        return axios.post(`${API_URL}signup`, {
            firstName,
            lastName,
            phoneNumber,
            email,
            password
        });
    }

    resetPasswordRequest(email){
        return axios.post(`${API_URL}reset-password-request`, {email});
    }

    resetPassword(password){
        return axios.post(`${API_URL}reset-password`, {password},{ headers: authHeader() });
    }
    getCurrentUser(){
        return JSON.parse(sessionStorage.getItem('user'));
    }

    handleResponse(response) {
        if (response.data.token) {
            sessionStorage.setItem('user', JSON.stringify(response.data));
            sessionStorage.setItem('token', response.data.token);
        }
        return response.data;
    }

    isUserLoggedIn(){
        return sessionStorage.getItem('user') !== null;
    }
}

export default new AuthService();