import axios from "axios";
import Footer from "../Footer";
import AdminHeader from "./AdminHeader";
import { useState } from "react";
import authHeader from "../auth-header";

const Email = () => {
    const [newEmail, setNewEmail] = useState({
        subject: "",
        message: "",
        imageUrl: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewEmail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}email`, newEmail, { headers: authHeader() });
            // Optionally, you can reset the form after submission
            setNewEmail({ subject: "", message: "", imageUrl: "" });
            alert("Email sent successfully!");
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send email. Please try again.");
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <AdminHeader />

            <div className="flex flex-col items-center justify-center p-6 mt-36">
                <h2 className="text-3xl font-bold mb-6">Send Email</h2>
                <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div>
                            <label className="block text-lg font-semibold mb-1">Subject:</label>
                            <input
                                type="text"
                                name="subject"
                                value={newEmail.subject}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-lg font-semibold mb-1">Message:</label>
                            <textarea
                                name="message"
                                value={newEmail.message}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="4"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-lg font-semibold mb-1">Display URL:</label>
                            <input
                                type="text"
                                name="imageUrl"
                                value={newEmail.imageUrl}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>

                        <button className="bg-gray-900 text-white py-3 rounded-lg font-semibold hover:bg-gray-800 transition">Send</button>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Email;
