import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { HeartIcon as OutlineHeartIcon } from '@heroicons/react/outline';
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/solid';
import Footer from './Footer';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from './auth-header';


const Item = () => {
  const { itemId } = useParams(); // Obtém o itemId dos parâmetros da rota
  const [item, setItem] = useState(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const navigate = useNavigate();

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items/${itemId}`);
        setItem(response.data);
      } catch (error) {
        console.error('Erro ao buscar item:', error);
      }
    };

    fetchItem();
  }, [itemId]);

  useEffect(() => {
    const checkWishlist = () => {
      const userString = sessionStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}wishlists/${user.id}/${itemId}`)
          .then(response => {
            if (response.data) {
              setIsFavorited(true);
            }
          })
          .catch(error => {
            console.error('Erro ao verificar wishlist:', error);
          });
      } else {
        const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
        const isInWishlist = wishlist.some(wishlistItem => wishlistItem.itemId === itemId);
        if (isInWishlist) {
          setIsFavorited(true);
        }
      }
    };

    checkWishlist();
  }, [itemId]);

  const addToCart = async () => {
    const userString = sessionStorage.getItem('user');
    if (userString) {
      // Usuário está logado, prosseguir com a solicitação da API
      const user = JSON.parse(userString);
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}carts`, {
          userId: user.id,
          itemId: item.itemId,
        },{ headers: authHeader() });
        toast.success('Item adicionado ao carrinho com sucesso!');
      } catch (error) {
        console.error('Erro ao adicionar item ao carrinho:', error);
        toast.info(error.response.data.message);
        setLimitReached(true);
      }
    } else {
      // Usuário não está logado, usar sessionStorage
      const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
  
      // Verificar se o item já existe no carrinho
      const existingItem = cart.find(cartItem => cartItem.itemId === item.itemId);
  
      if (existingItem) {
        // Se o item já existe, incrementar a quantidade
        existingItem.quantity += 1;
      } else {
        // Se o item não existe, adicionar com quantidade = 1
        cart.push({ ...item, quantity: 1 });
      }
  
      // Atualizar o sessionStorage com o novo carrinho
      sessionStorage.setItem('cart', JSON.stringify(cart));
  
      // Mostrar mensagem de sucesso
      toast.success('Item adicionado ao carrinho!');
    }
  };
  
  const addToWishlist = async (e) => {
    e.stopPropagation();
    toggleFavorite();

    const userString = sessionStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}wishlists`, {
          userId: user.id,
          itemId: item.itemId,
        },{ headers: authHeader() });
        toast.success('Item adicionado à lista de desejos');
      } catch (error) {
        console.error('Erro ao adicionar item à lista de desejos:', error);
      }
    } else {
      const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
      wishlist.push(item);
      sessionStorage.setItem('wishlist', JSON.stringify(wishlist));
      toast.success('Item adicionado à lista de desejos');
    }
  };

  const removeFromWishlist = async (e) => {
    e.stopPropagation();
    toggleFavorite();

    const userString = sessionStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}wishlists/${user.id}/${item.itemId}`);
        if (response.data && response.data.wishListId) {
          await axios.delete(`${process.env.REACT_APP_BACKEND_URL}wishlists/${response.data.wishListId}`,{ headers: authHeader() });
          toast.info('Item removido da lista de desejos');
        }
      } catch (error) {
        console.error('Erro ao remover item da lista de desejos:', error);
      }
    } else {
      const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
      const updatedWishlist = wishlist.filter(wishlistItem => wishlistItem.itemId !== itemId);
      sessionStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      toast.info('Item removido da lista de desejos');
    }
  };

  if (!item) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <p className="text-gray-600 text-lg">Carregando detalhes do item...</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Header Component */}
      <Header />
  
      {/* Toast Container for Notifications */}
      <ToastContainer position="bottom-right" autoClose={3000} />
  
      {/* Main content area */}
      <div className="container mx-auto py-16 px-4 lg:px-8 flex-grow bg-[url('./bg2.png')] bg-center bg-no-repeat">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-8">
          
          {/* Left-side image and video display */}
          <div className="flex flex-col items-center space-y-8">
            {item.frontImage && (
              <img
                src={item.frontImage}
                alt={`${item.name} Frontal`}
                className="w-full object-contain"
              />
            )}
            {item.leftImage && (
              <img
                src={item.leftImage}
                alt={`${item.name} Esquerda`}
                className="w-full object-contain"
              />
            )}
            {item.rightImage && (
              <img
                src={item.rightImage}
                alt={`${item.name} Direita`}
                className="w-full object-contain"
              />
            )}
            {item.backImage && (
              <img
                src={item.backImage}
                alt={`${item.name} Traseira`}
                className="w-full object-contain"
              />
            )}
            {item.video && (
              <video className="w-full" controls>
                <source src={item.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
  
      {/* Right-side sticky content */}
      <div className="flex flex-col items-center lg:items-start space-y-6 max-w-xs mx-auto mt-24 lg:sticky lg:top-20 lg:self-start lg:justify-center lg:mx-auto">
        <div className="flex items-center justify-center lg:justify-start w-full">
          <h1 className="text-4xl font-extrabold text-gray-900 flex-grow text-center lg:text-left">{item.name}</h1>
          <div
            onClick={(e) => {
              isFavorited ? removeFromWishlist(e) : addToWishlist(e);
            }}
            className="ml-4 cursor-pointer"
          >
            {isFavorited ? (
              <SolidHeartIcon className="h-8 w-8 text-red-500" />
            ) : (
              <OutlineHeartIcon className="h-8 w-8 text-black" />
            )}
          </div>
        </div>
        <h1 className="text-xl font-extrabold text-gray-900 text-center lg:text-left">{item.brand}</h1>
        <p className="text-gray-700 leading-relaxed text-center lg:text-left">
          {item.description.split('/').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
        <span className="text-3xl font-semibold text-gray-800 text-center lg:text-left">R${parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
        <span className="text-sm font-semibold text-gray-800 text-center lg:text-left">
          Preço de varejo: R${parseFloat(item.retailPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
        <span className="text-sm font-semibold text-gray-800 text-center lg:text-left">
          Restantes em estoque: <span className={item.stock <= 10 ? 'text-red-600' : 'text-black'}>{item.stock}</span>
        </span>

        <div className="flex flex-col items-center lg:items-start w-full mt-6">
          {item.stock > 0 ? (
            <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 items-center w-full">
              <button 
                className="bg-gray-900 text-white py-3 px-8 rounded-lg font-semibold hover:bg-gray-800 transition w-full lg:w-auto" 
                onClick={addToCart}
              >
                Adicionar ao Carrinho
              </button>
              <button 
                className="bg-white text-gray-900 border border-gray-300 py-3 px-8 rounded-lg font-semibold hover:bg-gray-100 transition w-full lg:w-auto" 
                onClick={() => { addToCart(); navigate("/cart"); }}
              >
                Comprar Agora
              </button>
            </div>              
          ) : (
            <button className="bg-gray-400 text-white py-3 px-8 rounded-lg font-semibold cursor-not-allowed w-full lg:w-auto" disabled>
              Esgotado
            </button>
          )}
        </div>
      </div>

        </div>
      </div>
  
      {/* Footer Component */}
      <Footer />
    </div>
  );
  
};

export default Item;
