import React, { useState } from 'react';

const EditForm = ({ selectedItem, brands, handleEditSubmit, setIsEditing }) => {
    const [editedItem, setEditedItem] = useState(selectedItem);

    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Edit Item</h2>
            <form onSubmit={(e)=>{
                e.preventDefault();
                handleEditSubmit(editedItem);
                }} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2">Name</label>
                                    <input
                                        type="text"
                                        value={editedItem.name}
                                        onChange={(e) => setEditedItem({ ...editedItem, name: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2">Brand</label>
                                    <select
                                        value={editedItem.brand}
                                        onChange={(e) => setEditedItem({ ...editedItem, brand: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    >
                                     {brands.map((brand) => (
                                        <option key={brand.brandId} value={brand.name}>
                                            {brand.name}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2">Description</label>
                                    <textarea
                                        value={editedItem.description}
                                        onChange={(e) => setEditedItem({ ...editedItem, description: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2">Category</label>
                                    <select
                                        value={editedItem.category}
                                        onChange={(e) => setEditedItem({ ...editedItem, category: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    >
                                        <option value="">Select a category</option>
                                        <option value="BOLSAS">Bolsas</option>
                                        <option value="OCULOS">Óculos</option>
                                        <option value="PERFUMARIA">Perfumes</option>
                                        <option value="CALCADOS">Calçados</option>
                                        <option value="MAQUIAGEM">Maquiagem</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div>
                                    <label className="block mb-2">Retail Price</label>
                                    <input
                                        type="number"
                                        value={editedItem.retailPrice}
                                        onChange={(e) => setEditedItem({ ...editedItem, retailPrice: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2">Price</label>
                                    <input
                                        type="number"
                                        value={editedItem.price}
                                        onChange={(e) => setEditedItem({ ...editedItem, price: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2">Actual Price</label>
                                    <input
                                        type="number"
                                        value={editedItem.actualPrice}
                                        onChange={(e) => setEditedItem({ ...editedItem, actualPrice: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2">Stock</label>
                                    <input
                                        type="number"
                                        value={editedItem.stock}
                                        onChange={(e) => setEditedItem({ ...editedItem, stock: e.target.value })}
                                        className="border rounded w-full p-2"
                                        required
                                    />
                                </div>
                            </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2">Front Image Url</label>
                        <input
                            type="text"
                            name="frontImage"
                            value={editedItem.frontImage}
                            onChange={(e) => setEditedItem({ ...editedItem, frontImage: e.target.value })}
                            className="border rounded w-full p-2"  
                        />
                    </div>
                    <div>
                        <label className="block mb-2">Back Image Url</label>
                        <input
                            type="text"
                            name="backImage"
                            value={editedItem.backImage}
                            onChange={(e) => setEditedItem({ ...editedItem, backImage: e.target.value })}
                            className="border rounded w-full p-2"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2">Left Image Url</label>
                        <input
                            type="text"
                            name="leftImage"
                            value={editedItem.leftImage}
                            onChange={(e) => setEditedItem({ ...editedItem, leftImage: e.target.value })}
                            className="border rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label className="block mb-2">Right Image Url</label>
                        <input
                            type="text"
                            name="rightImage"
                            value={editedItem.rightImage}
                            onChange={(e) => setEditedItem({ ...editedItem, rightImage: e.target.value })}
                            className="border rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label className="block mb-2">Video Url</label>
                        <input
                            type="text"
                            name="video"
                            value={editedItem.video}
                            onChange={(e) => setEditedItem({ ...editedItem, video: e.target.value })}
                            className="border rounded w-full p-2"
                        />
                    </div>
                </div>

                <div className="flex justify-end">
                    <button 
                        type="submit" 
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        Save Changes
                    </button>
                    <button 
                        type="button" 
                        onClick={() => setIsEditing(false)} 
                        className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
    )
}

export default EditForm;