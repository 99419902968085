import { useState } from "react";

const AddingItemForm = ({ addItem, brands, setIsAdding }) => {

    console.log("AddingItemForm rendered");
    const [newItem, setNewItem] = useState({
        name: "",
        description: "",
        brand: "Prada",
        retailPrice: "",
        price: "",
        actualPrice: "",
        stock: "",
        category: "",
        frontImage: null,
        backImage: null,
        leftImage: null,
        rightImage: null,
        video: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewItem((prevState) => ({
            ...prevState,
            [name]: name === "stock" ? Number(value) : value, // Convert stock to number
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            addItem(newItem);
        } catch (error) {
            console.error("Error adding item:", error);
        }
        setNewItem({
            name: "",
            description: "",
            brand: "Prada",
            retailPrice: "",
            price: "",
            actualPrice: "",
            stock: "",
            category: "",
            leftImage: null,
            rightImage: null,
            frontImage: null,
            backImage: null,
        });
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Add Item</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-2">Name</label>
                            <input
                                type="text"
                                name="name" // Ensure name attribute is set
                                value={newItem.name}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Brand</label>
                            <select
                                name="brand" // Ensure name attribute is set
                                value={newItem.brand}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            >
                                {brands.map((brand) => (
                                    <option key={brand.brandId} value={brand.name}>
                                        {brand.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-2">Description</label>
                            <textarea
                                name="description" // Ensure name attribute is set
                                value={newItem.description}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Category</label>
                            <select
                                name="category" // Ensure name attribute is set
                                value={newItem.category}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            >
                                <option value="">Select a category</option>
                                <option value="BOLSAS">Bolsas</option>
                                <option value="OCULOS">Oculos</option>
                                <option value="PERFUMARIA">Perfumaria</option>
                                <option value="CALCADOS">Calçados</option>
                                <option value="MAQUIAGEM">Maquiagem</option>
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label className="block mb-2">Retail Price</label>
                            <input
                                type="number"
                                name="retailPrice" // Ensure name attribute is set
                                value={newItem.retailPrice}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Price</label>
                            <input
                                type="number"
                                name="price" // Ensure name attribute is set
                                value={newItem.price}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Actual Price</label>
                            <input
                                type="number"
                                name="actualPrice" // Ensure name attribute is set
                                value={newItem.actualPrice}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-2">Stock</label>
                            <input
                                type="number"
                                name="stock" // Ensure name attribute is set
                                value={newItem.stock}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-2">Front Image Url</label>
                            <input
                                type="text"
                                name="frontImage"
                                value={newItem.frontImage}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Back Image Url</label>
                            <input
                                type="text"
                                name="backImage"
                                value={newItem.backImage}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-2">Left Image Url</label>
                            <input
                                type="text"
                                name="leftImage"
                                value={newItem.leftImage}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Right Image Url</label>
                            <input
                                type="text"
                                name="rightImage"
                                value={newItem.rightImage}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                            />
                        </div>
                        <div>
                            <label className="block mb-2">Video Url</label>
                            <input
                                type="text"
                                name="video"
                                value={newItem.video}
                                onChange={handleChange}
                                className="border rounded w-full p-2"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                        >
                            Save Changes
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsAdding(false)}
                            className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddingItemForm;
