import { ShoppingCartIcon, UserIcon, HeartIcon } from '@heroicons/react/outline';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from './auth_service';
import axios from 'axios';
import Cart from './Cart'; // Import the Cart component

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [brands, setBrands] = useState([]);
  const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showCart, setShowCart] = useState(false); // State to toggle the cart visibility
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // New state for mobile menu
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset;
      if (currentScrollTop > lastScrollTop) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    // Fetch brand data on component mount
    axios.get(`${process.env.REACT_APP_BACKEND_URL}brands`)
      .then(response => {
        setBrands(response.data);
      })
      .catch(error => {
        console.error('Error fetching brands:', error);
      });

    // Check if user is logged in by checking sessionStorage
    const user = sessionStorage.getItem('user');

    if (user) {
      setIsUserLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  return (
    <>
      {/* Dark background overlay */}
      {showCart && <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowCart(false)}></div>}
      
      <header
        className={`bg-white text-black border-t-2 border-b-2 border-gray-200 flex flex-col items-center pt-2 px-6 fixed top-0 left-0 w-full transition-all duration-300 ease-in-out z-50 ${
          showHeader ? 'translate-y-0' : '-translate-y-full'
        }  ${showBrandsDropdown ? 'h-36': 'h-24'}`}
      >
        <div className="flex items-center w-full justify-between">
          {/* Logo */}
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/logo.png`} className="ml-2 lg:ml-24 cursor-pointer text-lg w-56 font-bold lg:w-72" alt='Peça Estilosa BB' onClick={() => { navigate("/") }}></img>

          {/* Desktop Nav */}
          {/* <nav className="hidden lg:flex items-center ml-10">
            <div className="relative" onMouseEnter={() => setShowBrandsDropdown(true)}>
              <a href="#brands" className="text-gray-700 hover:text-gray-900 mr-6">Marcas</a>
            </div>
          </nav> */}

          {/* Mobile Menu Icon */}
          {/* <div className="lg:hidden ml-auto cursor-pointer" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <div className="space-y-1.5">
              <span className="block w-6 h-0.5 bg-gray-600"></span>
              <span className="block w-6 h-0.5 bg-gray-600"></span>
              <span className="block w-6 h-0.5 bg-gray-600"></span>
            </div>
          </div> */}

          {/* Right Icons */}
          <div className="flex space-x-6 ml-auto mr-4 sm:mr-32 relative">
            {/* User Icon */}
            <div className="relative" onMouseEnter={() => setShowUserDropdown(true)}>
              <UserIcon
                className={`w-6 h-6 cursor-pointer ${isUserLoggedIn ? 'fill-black' : 'text-gray-700'}`}
                onClick={() => !isUserLoggedIn && navigate("/login")}
              />
              {/* Dropdown for logged-in user */}
              {isUserLoggedIn && showUserDropdown && (
                <div
                  className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-lg z-50"
                  onMouseEnter={() => setShowUserDropdown(true)}  // Keep open on hover
                  onMouseLeave={() => setShowUserDropdown(false)} // Hide on hover out
                >
                  <a onClick={() => navigate("/purchase-history")} className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer">Histórico de Compras</a>
                  {/* <a href="/my-account" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">My Account</a> */}
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Encerrar Sessão
                  </button>
                </div>
              )}
            </div>

            {/* Wishlist Icon */}
            <HeartIcon className="w-6 h-6 cursor-pointer hover:scale-110 hover:text-red-600" onClick={() => { navigate("/wishlist") }} />

            {/* Cart Icon */}
            <ShoppingCartIcon className="w-6 h-6 cursor-pointer" onClick={() => setShowCart(!showCart)} />
          </div>
        </div>

        {/* Dropdown row for brands (desktop) */}
        {/* <div
          className={`hidden lg:flex w-full justify-center items-center mt-2 transition-opacity duration-300 ${
            showBrandsDropdown ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          onMouseEnter={() => setShowBrandsDropdown(true)}
          onMouseLeave={() => setShowBrandsDropdown(false)}
        >
          {brands.length > 0 ? (
            brands.map((brand) => (
              <span key={brand.id} className="text-gray-700 hover:text-gray-900 cursor-pointer mx-4" onClick={() => { navigate(`/brand/${brand.name}`) }}>
                {brand.name}
              </span>
            ))
          ) : (
            <span className="text-gray-700">No brands available</span>
          )}
        </div> */}
      </header>

      {/* Mobile Menu
      {mobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 bg-white p-4 z-50 w-1/4">
          <nav className="flex flex-col items-start">
            <button className="self-end mb-4 text-black" onClick={() => setMobileMenuOpen(false)}>X</button>
            <a href="#purses" className="text-gray-700 hover:text-gray-900 mb-4">Bolsas</a>
            <a href="#glasses" className="text-gray-700 hover:text-gray-900 mb-4">Oculos</a>
            <a href="#perfumes" className="text-gray-700 hover:text-gray-900 mb-4">Perfumes</a>
            <a href="#brands" className="text-gray-700 hover:text-gray-900">Marcas</a>
          </nav>
        </div>
      )} */}

      {/* Cart component */}
      {showCart && (
        <div className="fixed top-16 right-0 w-80 h-full bg-white shadow-lg z-50">
          <Cart />
        </div>
      )}
    </>
  );
};

export default Header;
