import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authHeader from "./auth-header.js";
import CartItemMini from "./CartItemMini.js";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userString = sessionStorage.getItem('user');
    if (userString) {
      setIsUserLoggedIn(true);
      const user = JSON.parse(userString);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}carts/${user.id}`,{ headers: authHeader() })
        .then(response => {
          setCartItems(response.data);
        })
        .catch(error => {
          console.error('Erro ao buscar itens do carrinho:', error);
        });
    } else {
      setIsUserLoggedIn(false);
      const cartItemsFromSession = JSON.parse(sessionStorage.getItem('cart'));
      if (cartItemsFromSession) {
        setCartItems(cartItemsFromSession);
        console.log('Itens do carrinho obtidos do session storage:', cartItemsFromSession);
      }
    }
  }, []);

  const handleDelete = (cartId) => {
    const userString = sessionStorage.getItem('user');
    if (userString) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}carts/${cartId}`,{ headers: authHeader() })
        .catch(error => {
          console.error('Erro ao deletar item do carrinho:', error);
        });
      setCartItems((prevItems) => prevItems.filter((item) => item.cartItemId !== cartId));
    } else {
      const cartItemsFromSession = JSON.parse(sessionStorage.getItem('cart'));
      const updatedCarts = cartItemsFromSession.filter(cartItem => cartItem.itemId !== cartId);
      sessionStorage.setItem("cart", JSON.stringify(updatedCarts));
      setCartItems(updatedCarts);
    }
  }

  return (
    <div className="p-4 flex flex-col min-h-screen">
      <h2 className="text-xl font-semibold mb-4">Seu Carrinho</h2>
      {cartItems.length > 0 ? (
        <div className="flex-grow flex flex-col space-y-4">
          {/* Renderiza condicionalmente CartItem ou CartItemSession com base no status de login */}
          {cartItems.map((item) =>
              <CartItemMini key={item.itemId} item={item} onDelete={handleDelete} />
          )}
        </div>
      ) : (
        <p>Seu carrinho está vazio.</p>
      )}
      {/* Garante que o botão seja exibido na parte inferior */}
      {cartItems.length > 0 ? (
        <div className="ml-10 w-full">
          <button className="py-2 px-6 bg-white text-black border-black border-solid border font-semibold hover:bg-gray-200 transition self-end mb-3 w-4/5 mx-auto" 
          onClick={() => { navigate("/cart") }}>
            Ver Carrinho
          </button>
          <button className="py-2 px-6 bg-black text-white font-semibold hover:bg-gray-800 transition self-end mb-28 w-4/5 mx-auto" onClick={() => { navigate("/cart") }}>
            Finalizar Compra
          </button>
        </div>
      ) : null}

    </div>
  );
};

export default Cart;
