import { FaTimesCircle } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Cancelled = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(10); // Timer set to 10 seconds
    const {orderId} = useParams();

    useEffect(() => {
        
        axios.put(`${process.env.REACT_APP_BACKEND_URL}orders/cancelled/${orderId}`)
        .catch(error => {
            alert('Error setting order to pending:', error);
            navigate('/');
        });

        // Start the countdown
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        // Redirect after 10 seconds
        const timeout = setTimeout(() => {
            navigate('/'); 
        }, 10000);

        // Clean up timers on component unmount
        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };
    }, [navigate]);

    return (
        <div>
            <Header />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4 rounded-lg">
                <FaTimesCircle className="text-red-500 text-6xl mb-6" />
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Pedido Cancelado</h1>
                <p className="text-lg text-gray-600 mb-6">Sua compra foi cancelada. Se precisar de ajuda, entre em contato com o suporte.</p>
                <p className="text-lg text-gray-600 mb-6">
                    Redirecionando em {countdown} segundos...
                </p>
               
            </div>
            <Footer />
        </div>
    );
}

export default Cancelled;
