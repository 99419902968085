import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import AdminHeader from "./AdminHeader";
import EditForm from "./EditForm";
import AddingItemForm from "./AddingItemForm";
import authHeader from "../auth-header";

const Inventory = () => {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false); // State to manage editing
    const [brands, setBrands] = useState([]); 
    const [isDeleting, setIsDeleting] = useState(false); // State to manage deletion
    const [isAdding, setIsAdding] = useState(false); // State to manage adding
    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items`);
                setItems(response.data);
            } catch (error) {
                console.error("Error fetching items:", error);
            }
        };

        fetchItems();
    }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}brands`);
                setBrands(response.data);
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };

        fetchBrands();
    }
    , []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(items.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const handleNextImage = () => {
        if (selectedItem) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
        }
    };

    const handlePrevImage = () => {
        if (selectedItem) {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + 4) % 4);
        }
    };

// Function to handle form submission
const handleEditSubmit = async (editedItem) => {

    const updatedItem = {
        ...editedItem
    };

    try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}items/${selectedItem.itemId}`, updatedItem, { headers: authHeader() });
        setIsEditing(false);
        // Refresh the item list
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items`);
        setItems(response.data);
        setSelectedItem({ ...selectedItem, ...updatedItem }); // Update the selected item with the edited data
    } catch (error) {
        console.error("Error updating item:", error);
    }
};

const handleDelete = async () => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}items/${selectedItem.itemId}`, { headers: authHeader() });
        setIsDeleting(false);
        // Refresh the item list
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items`);
        setItems(response.data);
        setSelectedItem(null);
    } catch (error) {
        console.error("Error deleting item:", error);
    }
};

const addItem = async (newItem) => {
   
        // Construct the payload with the base64 encoded images
        const payload = {
            name: newItem.name,
            description: newItem.description,
            brand: newItem.brand,
            retailPrice: newItem.retailPrice,
            price: newItem.price,
            actualPrice: newItem.actualPrice,
            stock: newItem.stock,
            category: newItem.category,
            leftImage: newItem.leftImage,
            rightImage: newItem.rightImage,
            frontImage: newItem.frontImage,
            backImage: newItem.backImage,
            video: newItem.video
        };

        try{
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}items`, payload, { headers: authHeader() });

            setIsAdding(false);

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items`);
            setItems(response.data);

        }
        catch(error){
            console.error("Error adding item:", error);
        }
       
    } 


    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <AdminHeader />

            <div className="flex-grow p-6 mt-24">
                <div className="flex justify-between">
                    <div className="w-1/2">
                        <div className="overflow-x-auto bg-slate-200 p-7 rounded">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Inventory</h2>
                                <button 
                                    onClick={() => setIsAdding(true)} 
                                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                >
                                    Add
                                </button>
                            </div>
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Image</th>
                                        <th className="py-2 px-4 border-b">Name</th>
                                        <th className="py-2 px-4 border-b">Brand</th>
                                        <th className="py-2 px-4 border-b">Actual Price</th>
                                        <th className="py-2 px-4 border-b">Stock</th>
                                        <th className="py-2 px-4 border-b">Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item) => (
                                        <tr
                                            key={item.itemId}
                                            onClick={() => {
                                                setSelectedItem(item);
                                                setCurrentImageIndex(0);
                                               
                                            }}
                                            className="cursor-pointer hover:bg-gray-100"
                                        >
                                            <td className="py-2 px-4 border-b">
                                                <img src={item.frontImage} alt="Front" className="w-16 h-16 object-cover" />
                                            </td>
                                            <td className="py-2 px-4 border-b">{item.name}</td>
                                            <td className="py-2 px-4 border-b">{item.brand}</td>
                                            <td className="py-2 px-4 border-b">{parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="py-2 px-4 border-b">{item.stock}</td>
                                            <td className="py-2 px-4 border-b">{item.category}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-between mt-4 items-center">
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Previous
                                </button>

                                <div>
                                    Page {currentPage} of {totalPages}
                                </div>

                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage >= totalPages}
                                    className={`px-4 py-2 bg-gray-200 rounded ${currentPage >= totalPages ? 'cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 p-6 bg-white rounded shadow-md flex flex-col items-center">
                        {selectedItem ? (
                           <div className="text-center">
                           <h3 className="text-xl font-bold mb-4">{selectedItem.name}</h3>
                           <div className="flex justify-center mb-4">
                               <button 
                                   onClick={handlePrevImage} 
                                   className="px-2 py-1 rounded hover:bg-gray-100"
                               >
                                   &#9664;
                               </button>
                               <img 
                                   src={selectedItem[["frontImage", "backImage", "leftImage", "rightImage"][currentImageIndex]]} 
                                   alt={`Image ${currentImageIndex}`} 
                                   className="w-80 h-80 object-cover mx-4"
                               />
                               <button 
                                   onClick={handleNextImage} 
                                   className="px-2 py-1 rounded hover:bg-gray-100"
                               >
                                   &#9654;
                               </button>
                           </div>
                           <p><strong>Description:</strong> {selectedItem.description}</p>
                           <p><strong>Brand:</strong> {selectedItem.brand}</p>
                           <p><strong>Retail Price:</strong> R${parseFloat(selectedItem.retailPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                           <p><strong>Price:</strong> R${parseFloat(selectedItem.price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                           <p><strong>Actual Price:</strong> R${parseFloat(selectedItem.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                           <p><strong>Stock:</strong> {selectedItem.stock}</p>
                           <p><strong>Category:</strong> {selectedItem.category}</p>
                       
                           {/* Edit and Delete Buttons */}
                           <div className="mt-4">
                               <button 
                                   onClick={() => setIsEditing(true)} 
                                   className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                               >
                                   Edit
                               </button>
                               <button 
                                   onClick={() => setIsDeleting(true)} 
                                   className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                               >
                                   Delete
                               </button>
                           </div>
                       </div>
                       
                        ) : (
                            <p className="text-gray-500">Select an item to see the details.</p>
                        )}
                    </div>
                </div>
            </div>

            { isDeleting && selectedItem && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Delete Item</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <div className="flex justify-center">
                <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Delete
                </button>
                <button
                    onClick={() => setIsDeleting(false)}
                    className="ml-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
)}


            {isEditing && selectedItem && (
                <EditForm selectedItem={selectedItem} brands={brands} handleEditSubmit={handleEditSubmit} setIsEditing={setIsEditing} />
            )}

            {isAdding && (
                <AddingItemForm brands={brands} addItem={addItem} setIsAdding={setIsAdding} />
            )}

                        <Footer />
                    </div>
                );
            };

export default Inventory;
