import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeartIcon as OutlineHeartIcon } from '@heroicons/react/outline';
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from './auth-header';

const ItemCard = ({ item, onRemove }) => {
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  useEffect(() => {
    const checkWishlist = async () => {
      const userString = sessionStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}wishlists/${user.id}/${item.itemId}`);
          if (response.data) {
            setIsFavorited(true);
          }
        } catch (error) {
          console.error('Error checking wishlist:', error);
        }
      } else {
        const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
        if (wishlist.some(wish => wish.itemId === item.itemId)) {
          setIsFavorited(true);
        }
      }
    };

    checkWishlist();
  }, [item.itemId]);

  const addToWishlist = async (e) => {
    e.stopPropagation();
    toggleFavorite();

    const userString = sessionStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}wishlists`, {
          userId: user.id,
          itemId: item.itemId,
        }, { headers: authHeader() });
        toast.success('Item added to wishlist!');
        console.log(response);
      } catch (error) {
        toast.error('Error adding item to wishlist');
        console.error('Error adding item to wishlist:', error);
      }
    } else {
      const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
      if (!wishlist.some(wish => wish.itemId === item.itemId)) {
        wishlist.push(item);
        sessionStorage.setItem('wishlist', JSON.stringify(wishlist));
        toast.success('Item added to wishlist!');
      }
    }
  };

  const removeFromWishlist = async (e) => {
    e.stopPropagation();
    toggleFavorite();

    const userString = sessionStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}wishlists/${user.id}/${item.itemId}`);
        if (response.data && response.data.wishListId) {
          await axios.delete(`${process.env.REACT_APP_BACKEND_URL}wishlists/${response.data.wishListId}`, { headers: authHeader() });
          toast.info('Item removed from wishlist');
          if (onRemove) {
            onRemove(response.data.wishListId);
          }
        }
      } catch (error) {
        console.error('Error removing item from wishlist:', error);
      }
    } else {
      const wishlist = JSON.parse(sessionStorage.getItem('wishlist')) || [];
      const updatedWishlist = wishlist.filter(wish => wish.itemId !== item.itemId);
      sessionStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      toast.info('Item removed from wishlist');
      if (onRemove) {
        onRemove(item.itemId);
      }
    }
  };

  return (
    <div 
      className="bg-gray-100 shadow-lg rounded-lg overflow-hidden cursor-pointer w-80 h-96 flex flex-col relative group" 
      onClick={() => navigate(`/items/${item.itemId}`)}
    >
      <ToastContainer position="bottom-right" autoClose={3000} />
      
      {/* Image Container */}
      <div className="relative flex-grow">
        <img
          src={item.frontImage}
          alt={`${item.name} - Front`}
          className="w-full h-48 object-cover transition-opacity duration-500 ease-in-out group-hover:opacity-0"
        />
        <img
          src={item.backImage}
          alt={`${item.name} - Back`}
          className="w-full h-48 object-cover absolute top-0 left-0 opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100"
        />
      </div>

      <div className="p-3 flex flex-col justify-between flex-grow">
        <div className="flex items-center justify-center relative">
          <h3 className="text-lg font-semibold mb-1 text-center flex-grow">{item.brand}</h3>
          <div onClick={(e) => isFavorited ? removeFromWishlist(e) : addToWishlist(e)} className="cursor-pointer">
            {isFavorited ? <SolidHeartIcon className="h-6 w-6 text-red-600" /> : <OutlineHeartIcon className="h-6 w-6 text-gray-500" />}
          </div>
        </div>
        <h4 className="text-md font-semibold text-gray-500 text-center">{item.name}</h4>

        <p className="text-sm text-gray-500 text-center">
          Preço de loja: R${parseFloat(item.retailPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </p>
        <p className="text-md font-semibold mt-2">
          R${parseFloat(item.actualPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </p>
      </div>
    </div>
  );
};

export default ItemCard;
